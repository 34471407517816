export default [{
    _name: 'CSidebarNav',
    _children: [{
            _name: 'CSidebarNavItem',
            name: 'sidebar.dashboard',
            to: '/dashboard',
            icon: 'cil-speedometer',
            items: []
        },
        {
            _name: 'CSidebarNavItem',
            name: 'Articles',
            to: '/articles',
            icon: 'cil-list',
            items: []
        },
        {
            _name: 'CSidebarNavItem',
            name: 'Quizs',
            to: '/quizs',
            icon: 'cil-list',
            items: []

        },
        {
            _name: 'CSidebarNavItem',
            name: 'Pages',
            to: '/pages',
            icon: 'cil-list',
            items: []
        },
        {
            _name: 'CSidebarNavDropdown',
            name: 'General Setting',
            route: '/general-setting',
            icon: 'cil-settings',
            items: [
                {
                    name: ' - Send Notification',
                    to: '/general-setting/send-notification'
                },
                {
                    name: ' - Sections',
                    to: '/general-setting/sections'
                },
                {
                    name: ' - Article Categories',
                    to: '/general-setting/article-categories'
                },
                {
                    name: ' - Quiz Categories',
                    to: '/general-setting/quiz-categories'
                },
                {
                    name: ' - Quiz Sub Categories',
                    to: '/general-setting/quiz-sub-categories'
                },
                {
                    name: ' - Ads Manage',
                    to: '/general-setting/ads'
                },
                {
                    name: ' - Setting',
                    to: '/general-setting/setting'
                },
                {
                    name: ' - Company',
                    to: '/general-setting/companies'
                }
            ]
        }
    ]
}]